<template>
  <div class="container">
    <div class="breadcrumb">
      <p class="topic_path m-0">
        <router-link :to="{ name: 'AccountSettings'}" class="ashen-link">{{
            $t("account.default.pageTitle")
          }}
        </router-link>
        <i class="fas fa-angle-right form-control-color"></i> {{ $t("account.editWithPassword.changePassword") }}
      </p>
    </div>
    <div class="card text-first border-0">
      <div class="card-body mx-auto">
        <div class="row">
          <h3 class="fw-bold mb-4">{{ $t("account.editWithPassword.changePassword") }}</h3>
          <div class="col">
            <label for="exampleInputPassword1">{{ $t("account.editWithPassword.authenticationPassword") }}</label>
            <input v-model="oldPassword" class="form-control" type="password">
            <div v-show="errors['oldPassword']" id="err_password_old" class="form-error">{{
                errors['oldPassword']
              }}
            </div>
          </div>
        </div>
        <div class="row m-t-15">
          <div class="col">
            <label for="exampleInputPassword1">{{ $t("account.editWithPassword.newPassword") }}</label>
            <input v-model="newPassword" :placeholder="$t('firstTime.placeholderNewPassword')" class="form-control"
                   type="password">
            <div v-show="errors['newPassword']" id="err_password_new" class="form-error">{{
                errors['newPassword']
              }}
            </div>
          </div>
        </div>
        <div class="row m-t-15">
          <div class="col">
            <label for="exampleInputPassword1">{{ $t("account.editWithPassword.newPasswordConfirm") }}</label>
            <input v-model="newPasswordConfirm" class="form-control" type="password">
            <div v-show="errors['newPasswordConfirm']" id="err_password_confirm" class="form-error">
              {{ errors['newPasswordConfirm'] }}
            </div>
          </div>
        </div>
        <div class="row m-t-20">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-1 order-sm-0">
            <button class="btn btn-light bg-white w-100 mw-100 ashen-link text-decoration-none mt-2 light border-0 custom-btn"
                    @click="$router.push('account')">
              {{ $t("account.editWithPassword.btnCancel") }}
            </button>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-0 order-sm-1">
            <button class="btn btn-primary w-100 mw-100 ok btn-outline-light font-weight-bold mt-2 custom-btn"
                    v-on:click="doChange">
              {{ $t("account.editWithPassword.btnChange") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import {SetChangePassword} from "../assets/jsipclient/index";
import Common from "../assets/jsipclient/common";
import {mapGetters} from "vuex";

export default {
  name: "EditPasswordRequired",
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      errors: []
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    async doChange(step) {
      this.errors = [];
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        var params = new SetChangePassword()
        params.oldPassword = this.oldPassword
        params.newPassword = this.newPassword
        params.newPasswordConfirm = this.newPasswordConfirm
        const jsonObject = await api.usersUserIdChangePasswordPostAsync(user_id, params)

        const message = this.$t("account.editWithPassword.toastSuccessChangePassword")
        Common.showToast(message)
        this.$router.push({name: 'AccountSettings'});
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
  }
}
</script>

<style lang="scss" scoped>
::placeholder {
  color: #9EB8E7;
}

.form-error {
  max-width: 416px;
}
</style>
